import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import scrollTo from 'gatsby-plugin-smoothscroll'

const InternalFooter = ({ links }) => (
  <footer className="nav-internal-footer container container--wide">
    <ul>
      {links && links.map( (link, index) => {
        return (
          <li key={index}>
            {link.newWindow &&
              <a href={link.linkUrl} target="_blank" rel="noopener noreferrer">{link.linkText}</a>
            }
            {!link.newWindow &&
              <Link to={link.linkUrl}>{link.linkText}</Link>
            }
          </li>
        )
      })}
      {!links &&
        <>
          <li>
            <Link to="/info/faq/">FAQs</Link>
          </li>
          <li>
            <Link to="/info/access/">Access <span className="extra">Policy</span></Link>
          </li>
          <li>
            <Link to="/info/terms/">Terms <span className="extra">&amp; Conditions</span></Link>
          </li>
        </>
      }
    </ul>
    <button className="back-to-top" onClick={() => scrollTo('#top')}>
      <i className="icon-ios-arrow-up"></i>
      BACK TO TOP
    </button>
  </footer>
)

InternalFooter.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
}

export default InternalFooter
