import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"

class LibraryModal extends React.Component {
  constructor() {
    super()
    this.state = { isOpen: true }
    this.closeBtn = React.createRef()
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.closeBtn.current.focus()
      }.bind(this),
      0
    );
  }

  closeModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  closeWithEscape = (ev) => {
    if (ev.keyCode === 27) {
      this.closeModal()
    }
  }

  render() {
    const libraryModal = this.props.globalJson.libraryModal
    return (
      <div role="dialog" className={this.state.isOpen ? 'modal modal--small open': 'modal modal--small'}>
        <div className="modal-content">
          <button ref={this.closeBtn} className="close" onClick={this.closeModal} onKeyDown={this.closeWithEscape}><i className="icon-close"></i><span className="visuallyhidden">Close</span></button>
          <div className="grid-wrapper align--end">
            <div className="grid lap-one-third">
              <Img className="hidden-below-lap" fluid={libraryModal.image.localFile.childImageSharp.fluid} alt="" />
            </div>
            <div className="grid lap-two-thirds islet">
              <div className="inset-content">
                <h3 className="h2 landmarklet">{libraryModal.title}</h3>
                <p className="larger" dangerouslySetInnerHTML={{__html: libraryModal.description}} />
                <p className="landmarkless"><Link className="btn" to={libraryModal.buttonUrl}>{libraryModal.buttonLabel}</Link></p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-overlay" onClick={this.closeModal} aria-hidden="true"></div>
      </div>
    )
  }
}

LibraryModal.propTypes = {
  globalJson: PropTypes.shape({
    libraryModal: PropTypes.object.isRequired,
  }).isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strapiCenturionGlobal {
          libraryModal {
            title
            description
            buttonLabel
            buttonUrl
            image {
             localFile {
                childImageSharp {
                  fluid(maxWidth: 150) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ strapiCenturionGlobal }) => <LibraryModal globalJson={strapiCenturionGlobal} {...props} />}
  />
)
