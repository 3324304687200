import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"
import InternalFooter from "../components/InternalFooter"
import LanguageToggle from "../components/LanguageToggle"
import InfoBar from "../components/InfoBar"
import HeroCarousel from "../components/HeroCarousel"
import LoungeInfo from "../components/LoungeInfo"
import ReferralAd from "../components/ReferralAd"
import LoungeFeatures from "../components/LoungeFeatures"
import LoungeProviders from "../components/LoungeProviders"
import Video from "../components/Video"
import LibraryModal from "../components/LibraryModal"
import LocationMap from "../components/LocationMap"
import Legal from "../components/Legal"
import isAfter from 'date-fns/isAfter';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export default ({ data }) => {
  const lounge = data.strapiTheCenturionLounge;

  return (
    <Layout>
      <SEO metaTitle={lounge.metaTitle} />
      <Header airportName={lounge.airportName} />
      {lounge.languageLink && lounge.languageUrl &&
        <LanguageToggle languageLink={lounge.languageLink} languageUrl={lounge.languageUrl} />
      }
      <InfoBar inLounge={true} />
      {lounge.hasCalm && <div className="info-bar calm-info-bar" >
        Discover a moment of Calm before you fly <a href="/site/iah/calm">here</a>.
        </div>}
      {lounge.heroImages &&
        <HeroCarousel heroImages={lounge.heroImages} />
      }
      <div className="head">
        <div className="head-box">
          <h1>{lounge.airportName}</h1>
        </div>
      </div>
      {lounge.info &&
        <LoungeInfo info={lounge.info} inLounge={true} />
      }
      <ReferralAd referralUrl={lounge.info.referralUrl} />
      {lounge.features &&
        <LoungeFeatures features={lounge.features} />
      }
      {lounge.providers &&
        <LoungeProviders providers={lounge.providers} showDisclaimer={true} />
      }
      {lounge.video &&
        <Video video={lounge.video} />
      }
      <LibraryModal />
      <LocationMap />
      <Legal />
      <InternalFooter links={lounge.internalFooterLinks} />
    </Layout>
  )
}

export const query = graphql`
query ($url: String!) {
  strapiTheCenturionLounge(url: {eq: $url}) {
    url
    metaTitle
    airportCode
    airportName
    shortAirportName
    hasCalm
    languageLink
    languageUrl
    heroImages {
      heroImage {
        localFile {
          childImageSharp {
            desktopImage: fluid(maxWidth: 1200, maxHeight: 420, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
            mobileImage: fluid(maxWidth: 676, maxHeight: 540, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    info {
      title
      loungeTerminal
      loungeTerminalLetter
      loungeLocation
      alternateTextSize
      surveyText
      surveyUrl
      description
      inLoungeDescription
      smallMapImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      largeMapImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      referralUrl
    }
    features {
      featureImage {
      localFile {
          childImageSharp {
            fluid(maxWidth: 676) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      title
      description
      buttonText
      footnote
      amenities {
        iconId
        name
      }
      menuIntro
      strapiMenu {
        title
        hours
        menuColumns {
          menuItems {
            title
            item
          }
        }
        footnote
        creatorTitle
        creatorDescription
        creatorImage {
          localFile {
              childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    providers {
      name
      logo {
        localFile {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      description
      linkText
      linkUrl
    }
    video {
      videoTitle
      videoDescription
      youTubeVideoID
    }
    internalFooterLinks {
      linkText
      linkUrl
      newWindow
    }
  }
}
`
