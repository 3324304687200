import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"

function ReferralAd({ referralUrl }) {
  const { strapiCenturionGlobal } = useStaticQuery(
    graphql`
      query {
        strapiCenturionGlobal {
          referralAd {
            cardImageOne {
              localFile {
								childImageSharp {
                fluid(maxWidth: 280) {
                  ...GatsbyImageSharpFluid
                }
              }
              }
            }
            cardImageTwo {
              localFile {
								childImageSharp {
                fluid(maxWidth: 280) {
                  ...GatsbyImageSharpFluid
                }
              }
              }
            }
            textOne
            textTwo
            buttonText
            disclaimer
          }
        }
      }
    `
  )
  const referralAd = strapiCenturionGlobal.referralAd

  return (
    <div className="referral alt-gray-bg">
      <div className="container container--wide island text-center">
        <div className="grid-wrapper grid-wrapper--rev align--center">
          <div className="grid lap-one-half">
            <div className="referral-text-one">
              <h2 dangerouslySetInnerHTML={{__html: referralAd.textOne}} />
            </div>
            <div className="referral-text-two">
              <h2 dangerouslySetInnerHTML={{__html: referralAd.textTwo}} />
              {referralUrl &&
                <p className="landmarklet"><a className="btn" href={referralUrl} target="_blank" rel="noopener noreferrer">{referralAd.buttonText}</a></p>
              }
              <p className="legal landmarkless">{referralAd.disclaimer}</p>
            </div>
          </div>
          <div className="grid lap-one-half">
            <div className="referral-img-one">
              <Img fluid={referralAd.cardImageOne.localFile.childImageSharp.fluid} />
            </div>
            <div className="referral-img-two">
              <Img fluid={referralAd.cardImageTwo.localFile.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ReferralAd.propTypes = {
  referralUrl: PropTypes.string,
}

export default ReferralAd
